import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import useSiteSettings from '~utils/useSiteSettings'
import Section from '~components/Section'
import RichText from '~components/RichText'
import Image from '~components/Image'
import { Circle, Rarr } from '~components/Svg'
import resolveLink from '~utils/resolveLink'
import SanityLink from '~components/SanityLink'
import Column from '~components/Column'
import '@dotlottie/player-component'

const mobile = '@media (max-width: 880px)'

const FirstColumn = ({ data }) => {
	const [hover, setHover] = useState(false)
	const [animation, setAnimation] = useState()
	const [direction, setDirection] = useState('forward')
	const animationRef = useRef()

	useEffect(() => {
		if(animationRef?.current){
			const player = animationRef?.current
			setAnimation(player)
		}
	}, [animationRef])

	useEffect(() => {
		if (animation) {
			animation.play()
			const handleAnimation = e => {
				if (hover) {
					if(e.detail.frame > 229){
						animation.pause()
					}
				} else {
					if (direction === 'forward') {
						if(e.detail.frame > 120){
							animation?.seek(0)
						}
					} else if (direction === 'backward') {
						animation?.setDirection(-1)
						if(e.detail.frame <= 2){
							setDirection('forward')
							animation?.setDirection(1)
						} 
					}
				}
			}
			animation.addEventListener('frame', handleAnimation)
			return () => animation.removeEventListener('frame', handleAnimation)
		}
	}, [animation, hover, direction])

	const handleMouseLeave = () => {
		setDirection('backward')
		setHover(false)
	}

	return (
		<FirstCol 
			onMouseEnter={()=> setHover(true)}
			onMouseLeave={()=> handleMouseLeave()}
			css={css`:before{background: var(--black);}`}
		>
			<SiteTitle>
				{(data.home?.siteTitle).split('Places')}
				<br/>	
				{(data.home?.siteTitle).split('Jacky Winter')}
			</SiteTitle>
			<Text css={css`
				opacity: ${hover ? '1' : '0'};
				pointer-events: ${hover ? 'all' : 'none'};
			`}>
				<RichText content={data.home?.description}/>
			</Text>
			<ImageBlock>
				<dotlottie-player
					ref={animationRef}
					src='/animations/places.lottie'
					autoplay
				/>
				{/* <Graphic /> */}
			</ImageBlock>
		</FirstCol>
	)
}


const IndexPage = ({ data }) => {

	let gardens =	data.places.nodes.filter(node => node.title === 'Gardens').map(data => ( {...data, loopDuration: 100}))
	let gallery =	data.places.nodes.filter(node => node.title === 'Gallery').map(data => ( {...data, loopDuration: 168}))
	let park =	data.places.nodes.filter(node => node.title === 'Park').map(data => ( {...data, loopDuration: 100}))
	// let places =	data.places.nodes.filter(node => node.title === 'Places').map(data => ( {...data, loopDuration: 122}))
	let waters =	data.places.nodes.filter(node => node.title === 'Waters').map(data => ( {...data, loopDuration: 99}))

	const modData = [...gardens, ...waters, ...park, ...gallery]

	const allData = modData.map(data => ( {...data, lottie: `/animations/${(data.title).toLowerCase().replace(/\s/g, '')}.lottie`}))

	return (
		<>	
			<Seo/>
			<Wrap>
				<FirstColumn data={data} />
				{allData.map(place => (
					<Column 			
						key={place.title} 
						place={place}
					/>		
				))}
			</Wrap>
			<DataViewer data={data} name="data"/>
		</>
	)
}

const Wrap = styled(Section)`	
	padding-bottom: var(--xl);
	> div {
		${mobile}{
			grid-template-columns: repeat(1, 1fr);
			grid-column-gap: 0px;
		}
	}
`
const SiteTitle = styled.h2`
	margin-bottom: 4px;
	font-weight: 600;
	${mobile}{
		margin-bottom: 160px
	}
`
const FirstCol = styled.div`
	grid-column: span 1;
	position: relative;
	/* padding-top: 2px; */
	display: grid;
	grid-template-rows: max-content max-content 1fr;
	height: 100%;
	min-height: calc(100vh - (var(--xl) * 2));
	&:before{
		content: '';
		width: 1px;
		position: absolute;
    left: -13px;
		bottom: 0;
    top: 0;
		${mobile}{
			content: none;
		}
	}
	${mobile}{
		padding-top: 0px;
		grid-template-rows: max-content max-content max-content;
		height: auto;
		min-height: auto;
		margin-bottom: 54px;
		&:first-of-type{
			margin-bottom: 38px;
		}
	}
`
const Text = styled.h2`
	margin-bottom: var(--l);
	transition: opacity 0.25s;
	span {
		font-weight: 600;
		a {
			position: relative;
			text-decoration: underline;
		}
		${mobile}{
			display: none;
		}
	}
	${mobile}{
		opacity: 1;
		pointer-events: all;
	}
`
const ImageBlock = styled.div`
	margin-top: auto;
	position: relative;
	align-self: end;
	${mobile}{
		grid-row: 2;
		padding: 0 15%;
		margin-bottom: 108px;
	}
`
export const query = graphql`
  query HomeQuery {
		home: sanitySiteSettings{
			siteTitle
			description: _rawDescription(resolveReferences: {maxDepth: 6})
		}
    places: allSanityPlace(sort: {order: ASC, fields: rank}) {
			nodes {
				title
				description: _rawDescription(resolveReferences: {maxDepth: 6})
				learnMoreText
				learnMoreLink{
					...link
				}
				hoverImage {
					...imageWithAlt
				}
				lottieJSON
				featureColor {
					hex
				}
			}
		}
  }
`

IndexPage.propTypes = {
	data: PropTypes.object,
}
FirstColumn.propTypes = {
	data: PropTypes.object,
}

export default IndexPage
